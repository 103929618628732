//Oath typography
.oath-basetext {
  font-family: $font-dmsans-regular;
  letter-spacing: -0.01em;
}
.oath-eyebrow {
  font-size: 12px;
  letter-spacing: 0.1em;
  line-height: 14px;
  text-transform: uppercase;
  font-family: $font-dmsans-semiBold;
}
.oath-h1 {
  @extend .oath-basetext;
  font-size: 60px;
  line-height: 65px;
  font-family: $font-dmsans-light;
}
.oath-h2 {
  @extend .oath-basetext;
  font-size: 40px;
  line-height: 44px;
  font-family: $font-dmsans-light;
}
.oath-h3 {
  @extend .oath-basetext;
  font-size: 30px;
  line-height: 33px;
  font-family: $font-dmsans-light;
}
.oath-bodybig {
  @extend .oath-basetext;
  font-size: 25px;
  line-height: 33px;
}
.oath-h4 {
  @extend .oath-basetext;
  font-size: 20px;
  line-height: 25px;
  font-family: $font-dmsans-regular;
}
.oath-body {
  @extend .oath-basetext;
  font-size: 15px;
  line-height: 20px;
}
.oath-bodysmall {
  @extend .oath-basetext;
  font-size: 13px;
  line-height: 18px;
}
.oath-utility {
  @extend .oath-basetext;
  font-size: 11px;
  line-height: 16px;
}

.inkBlue {
  color: $inkBlue !important;
}
.oathBlue {
  color: $oathBlue;
}
.gray {
  color: $gray;
}
.white {
  color: $white;
}
.errorRed {
  color: $danger-color !important;
}

button,
input,
optgroup,
select,
textarea {
  font-family: $font-dmsans-regular;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-normal;
}

.title-dashboard {
  font-size: 40px;
  @media (max-width: 768px) {
    text-align: center;
  }
}
.title-dashboard,
.with-subtitle {
  margin-bottom: 0px !important;
}

.subtitle-dashboard {
  margin-top: 0px !important;
}

.title-permission {
  font-size: 25px;
}

a {
  color: $primary-color;
  &:hover,
  &:focus {
    color: $primary-color;
  }
}
h1,
.h1 {
  font-size: $font-size-h1;
  line-height: 1.15;
  margin-bottom: $margin-base-vertical * 2;

  small {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    opacity: 0.8;
  }
}
h2,
.h2 {
  font-size: $font-size-h2;
  margin-bottom: $margin-base-vertical * 2;
}
h3,
.h3 {
  font-size: $font-size-h3;
  margin-bottom: $margin-base-vertical * 2;
  line-height: 1.4em;
}
h4,
.h4 {
  font-size: $font-size-h4;
  line-height: 1.45em;
  margin-top: $margin-base-vertical * 2;
  margin-bottom: $margin-base-vertical;

  & + .category,
  &.title + .category {
    margin-top: -10px;
  }
}
h5,
.h5 {
  font-size: $font-size-h5;
  line-height: 1.4em;
  margin-bottom: 15px;
}
h6,
.h6 {
  font-size: $font-size-h6;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}
p {
  &.description {
    font-size: 1.14em;
  }
}

// i.fa{
//     font-size: 18px;
//     position: relative;
//     top: 1px;
// }

.title {
  font-weight: $font-weight-bold;

  &.title-up {
    text-transform: uppercase;

    a {
      color: $black-color;
      text-decoration: none;
    }
  }
  & + .category {
    margin-top: -10px;
  }
}

.description,
.card-description,
.footer-big p,
.card .footer .stats {
  color: $dark-gray;
  font-weight: $font-weight-light;
}
.category,
.card-category {
  text-transform: capitalize;
  font-weight: $font-weight-normal;
  color: $dark-gray;
  font-size: $font-size-mini;
}

.card-category {
  font-size: $font-size-h6;
}

.card-subcategory {
  text-transform: capitalize;
  font-weight: $font-weight-normal;
  color: $dark-gray;
  font-size: $font-size-xs;
}
.card-subcategory-value {
  font-size: $font-size-large-navbar;
}

.text-primary,
a.text-primary:focus,
a.text-primary:hover {
  color: $brand-primary !important;
}
.text-info,
a.text-info:focus,
a.text-info:hover {
  color: $brand-info !important;
}
.text-success,
a.text-success:focus,
a.text-success:hover {
  color: $brand-success !important;
}
.text-warning,
a.text-warning:focus,
a.text-warning:hover {
  color: $brand-warning !important;
}
.text-danger,
a.text-danger:focus,
a.text-danger:hover {
  color: $brand-danger !important;
}

.text-gray,
a.text-gray:focus,
a.text-gray:hover {
  color: $light-gray !important;
}
.text-purple,
a.text-purple:focus,
a.text-purple:hover {
  color: $purple !important;
}

.blockquote {
  border-left: none;
  border: 1px solid $default-color;
  padding: 20px;
  font-size: $font-size-blockquote;
  line-height: 1.8;

  small {
    color: $default-color;
    font-size: $font-size-small;
    text-transform: uppercase;
  }

  &.blockquote-primary {
    border-color: $primary-color;
    color: $primary-color;

    small {
      color: $primary-color;
    }
  }

  &.blockquote-danger {
    border-color: $danger-color;
    color: $danger-color;

    small {
      color: $danger-color;
    }
  }

  &.blockquote-white {
    border-color: $opacity-8;
    color: $white-color;

    small {
      color: $opacity-8;
    }
  }
}

.link-text {
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: $oathBlue;
  }

  &.disabled {
    color: $gray !important;
    cursor: not-allowed;
  }
}

.alert-link {
  color: $oathBlue;
  @extend .oath-body;
}

.form-category {
  text-transform: lowercase !important;
}