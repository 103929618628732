.carousel-container {
  width: -webkit-fill-available;
  display: flex;

  .carousel {
    position: relative;
    overflow: hidden;
    height: 500px;
    width: -webkit-fill-available;
  }

  .motion-container {
    position: absolute;
    height: 100%;
    width: 100%;
    align-content: flex-start;
    justify-items: center;

    &:hover {
      cursor: grab;
    }

    &:active {
      cursor: grabbing;
    }
  }
}

.dots-row {
  gap: 12px;
  display: flex;
  justify-content: center;

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: $gray;

    &.active {
      background-color: $white;
    }
  }
}
