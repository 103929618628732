.padding0 {
  padding: 0;
}

.margin0 {
  margin: 0;
}

.display-none {
  display: none !important;
}

.capitalize {
  text-transform: capitalize;
}

.gap4 {
  gap: 4px;
}

.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start;
}

.text-right {
  text-align: right;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.no-wrap {
  flex-wrap: nowrap;
}

.hidden-on-desktop {
  display: none !important;
}