.close-button {
  cursor: pointer;
  right: 15px;
  top: 15px;
  z-index: 1000;
  position: absolute;
}

.modal-component-overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(4, 5, 58, 0.5);
  z-index: 10000;
}

.modal-component-content {
  position: absolute;
  inset: 40px 0px;
  background-color: $white;
  outline: none;
  border: 1px solid $softGray;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  left: 0;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  overflow: hidden;
  padding: 27px 23px 9px;
  right: 0;
  width: 365px;
  text-align: center;
}