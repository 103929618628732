@media screen and (max-width: 991px) {
  .navbar {
    padding: 0;

    &.navbar-absolute {
      padding-top: 0;
      background-color: $inkBlue !important;
    }

    .navbar-brand {
      font-size: 16px;
      margin-right: 0;
    }
  }

  .table-action-row {
    display: grid !important;
    gap: 4px;
  }

  .date-filter-item {
    padding: 4px 8px;
    margin-right: 8px;

    span {
      color: $white !important;
    }

    &.active {
      border: none;
      background-color: $oathBlue;
      border-radius: 4px;
    }

    svg path {
      fill: $white !important;
    }
  }

  .paddingTop {
    padding-top: 15px !important;
  }

  .profile-photo .profile-photo-small {
    margin-left: -2px;
  }

  .button-dropdown {
    display: none;
  }

  #minimizeSidebar {
    display: none;
  }

  .navbar {
    .container-fluid {
      padding: 10px 15px;
    }

    .navbar-collapse {
      .input-group {
        margin: 0;
        margin-top: 5px;
      }
    }

    .navbar-nav {
      .nav-item:first-child {
        margin-top: 10px;
      }
      .nav-item:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .dropdown.show .dropdown-menu {
      display: block;
    }

    .dropdown .dropdown-menu {
      display: none;
    }

    .dropdown.show .dropdown-menu,
    .dropdown .dropdown-menu {
      border: 0;
      transition: none;
      -webkit-box-shadow: none;
      width: auto;
      margin: 0px 1rem;
      margin-top: 0px;
      box-shadow: none;
      position: static;
      padding-left: 10px;

      &:before {
        display: none;
      }
    }

    .dropdown-menu .dropdown-item:focus,
    .dropdown-menu .dropdown-item:hover {
      color: $white-color;
    }

    &.bg-white .dropdown-menu .dropdown-item:focus,
    &.bg-white .dropdown-menu .dropdown-item:hover {
      color: $default-color;
    }

    .navbar-toggler-bar {
      display: block;
      position: relative;
      width: 22px;
      height: 1px;
      border-radius: 1px;
      background: $default-color;

      & + .navbar-toggler-bar {
        margin-top: 7px;
      }

      & + .navbar-toggler-bar.navbar-kebab {
        margin-top: 3px;
      }

      &.bar2 {
        width: 17px;
        transition: width 0.2s linear;
      }
    }

    &.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
      background-color: $default-color;
    }

    & .toggled .navbar-toggler-bar {
      width: 24px;

      & + .navbar-toggler-bar {
        margin-top: 5px;
      }
    }
  }

  .wrapper {
    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }

  .nav-open {
    .main-panel {
      right: 0;
      @include transform-translate-x(260px);
    }

    .sidebar {
      @include transform-translate-x(0px);
    }

    body {
      position: relative;
      overflow-x: hidden;
    }

    .menu-on-right {
      .main-panel {
        @include transform-translate-x(-260px);
      }

      .navbar-collapse,
      .sidebar {
        @include transform-translate-x(0px);
      }

      .navbar-translate {
        @include transform-translate-x(-300px);
      }

      #bodyClick {
        right: 260px;
        left: auto;
      }
    }
  }

  .menu-on-right {
    .sidebar {
      left: auto;
      right: 0;
      @include transform-translate-x(260px);
    }
  }

  .bar1,
  .bar2,
  .bar3 {
    outline: 1px solid transparent;
  }
  .bar1 {
    top: 0px;
    @include bar-animation($topbar-back);
  }
  .bar2 {
    opacity: 1;
  }
  .bar3 {
    bottom: 0px;
    @include bar-animation($bottombar-back);
  }
  .toggled .bar1 {
    top: 6px;
    @include bar-animation($topbar-x);
  }
  .toggled .bar2 {
    opacity: 0;
  }
  .toggled .bar3 {
    bottom: 6px;
    @include bar-animation($bottombar-x);
  }

  @include topbar-x-rotation();
  @include topbar-back-rotation();
  @include bottombar-x-rotation();
  @include bottombar-back-rotation();

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 1;
    top: 0;
    right: 0;
    left: 260px;
    content: '';
    z-index: 9999;
    overflow-x: hidden;
    background-color: transparent;
    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }

  .footer {
    .copyright {
      text-align: right;
    }
  }

  .section-nucleo-icons .icons-container {
    margin-top: 65px;
  }

  .navbar-nav {
    .nav-link {
      i.fa,
      i.nc-icon {
        opacity: 0.5;
      }
    }
  }

  .sidebar,
  .bootstrap-navbar {
    position: fixed;
    display: block;
    top: 0;
    height: 100%;
    width: 260px;
    right: auto;
    left: 0;
    z-index: 1032;
    visibility: visible;
    overflow-y: visible;
    padding: 0;
    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    @include transform-translate-x(-260px);
  }

  .main-panel {
    width: 100%;
  }

  .timeline {
    &:before {
      left: 5% !important;
    }

    > li > .timeline-badge {
      left: 5% !important;
    }

    > li > .timeline-panel {
      float: right !important;
      width: 82% !important;

      &:before {
        border-left-width: 0 !important;
        border-right-width: 15px !important;
        left: -15px !important;
        right: auto !important;
      }

      &:after {
        border-left-width: 0 !important;
        border-right-width: 14px !important;
        left: -14px !important;
        right: auto !important;
      }
    }
  }

  //Mobile new styles start
  .navbar-logout {
    display: block;
  }

  .navbar-clients-col {
    justify-items: center;

    &.is-mobile {
      justify-items: inherit;
    }

    img {
      display: block !important;
    }
  }

  .filters-row {
    margin: 125px 0 0 !important;
    border: none !important;

    &:not(.with-padding) {
      justify-content: center !important;
    }
    &.with-padding {
      span {
        color: $white !important;
      }
      button {
        @include btn-styles($white, linear-gradient(270deg, $oathBlue 0%, $electricBlue 100%));
        color: $inkBlue;
        border: 1px solid $inkBlue;
    
        &:hover,
        &:focus {
          color: $white !important;
        }
      }
    }
  }

  .hidden-on-desktop {
    display: block !important;

    &.navbar-clients-container {
      border-top: 1px solid $white;
      border-bottom: 1px solid $white;
      padding: 0 0 6px;
    }
  }

  .navbar-transparent {
    border: none !important;
  }

  .navbar-select {
    width: calc(100% - 50px);
  }

  .react-select__single-value {
    color: $white !important;
  }

  .navbar-clients {
    .react-select__control {
      svg {
        background-color: $white !important;
        border: 1px solid $inkBlue !important;
        path {
          fill: $inkBlue !important;
        }
      }
    }
  }

  .hidden-on-mobile {
    display: none !important;
  }

  .recent-activity-clickable {
    display: none !important;
  }

  .recent-activity-sidebar {
    display: none;
  }

  .recent-activity-bottom {
    display: block;
  }

  .recent-activity-item {
    padding: 18px;
  }

  .bottom-sheet-header {
    display: block !important;
    background-color: $white;
    padding: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .main-panel {
    background-color: $inkBlue !important;

    .content {
      width: 100% !important;
    }
  }

  .custom-header-container {
    span {
      color: $white !important;
    }
    .rdtPrev span,
    .rdtNext span {
      color: $inkBlue !important;
    }
  }

  .recent-donations-table {
    margin-bottom: 80px;
  }

  .date-filter-container {
    max-width: 100%;
  }

  .custom-header-container .new-page-button button {
    @include btn-styles($white, linear-gradient(270deg, $oathBlue 0%, $electricBlue 100%));
    color: $inkBlue;
    border: 1px solid $inkBlue;

    &:hover,
    &:focus {
      color: $white !important;
    }
  }

  .card-accordion-header {
    background-color: $white;
  }
  //Mobile new styles end
}

@media (max-width: 991px) and (min-width: 768px) {
  .nav-tabs-navigation.verical-navs {
    padding: 0px 2px;
  }
}

@media screen and (min-width: 768px) {
  .footer {
    .footer-nav {
      padding-left: 21px;
    }

    .credits {
      padding-right: 15px;
    }
  }
}

@media screen and (min-width: 992px) {
  .navbar-collapse {
    background: none !important;
  }

  .navbar .navbar-toggle {
    display: none;
  }

  .navbar-nav {
    .nav-link {
      &.profile-photo {
        padding: 0;
        margin: 7px $padding-base-horizontal;
      }
    }
  }

  .section-nucleo-icons .icons-container {
    margin: 0 0 0 auto;
  }

  .dropdown-menu .dropdown-item {
    color: inherit;
  }

  .footer {
    .copyright {
      float: right;
      padding-right: 15px;
    }
  }
}

@media screen and (max-width: 768px) {
  .card-stats [class*='col-'] .statistics::after {
    display: none;
  }

  .main-panel .content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .footer {
    nav {
      display: block;
      margin-bottom: 5px;
      float: none;
    }
  }

  .landing-page .section-story-overview .image-container:nth-child(2) {
    margin-left: 0;
    margin-bottom: 30px;
  }

  .card {
    .form-horizontal {
      .col-md-3.col-form-label {
        text-align: left;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .nav-tabs-navigation.verical-navs {
    padding: 0 28px;
  }

  .typography-line {
    padding-left: 23% !important;

    span {
      width: 60px !important;
    }
  }

  .login-page,
  .lock-page,
  .register-page {
    .navbar {
      padding: 0.5rem 1rem;
    }
  }

  .footer {
    .footer-nav,
    .credits {
      margin: 0 auto !important;
    }

    .footer-nav {
      margin-bottom: 10px !important;
    }
  }

  .register-page {
    .content {
      padding-top: 5vh;
    }
    .footer {
      position: relative;
    }
    .info-area.info-horizontal {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 374px) {
  .login-page {
    .content {
      padding-top: 10vh;
    }
  }
}

@media screen and (max-width: 413px) {
  .fixed-plugin {
    .dropdown.show-dropdown.show {
      .dropdown-menu.show {
        width: 225px !important;

        &[x-placement='top-start'] {
          transform: translate3d(0, 400px, 0) !important;
        }

        &:before,
        &:after {
          bottom: 360px !important;
        }
      }
    }
  }
}
