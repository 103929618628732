.table {
  .img-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
  }

  .img-row {
    max-width: 60px;
    width: 60px;
  }

  .form-check {
    margin: 0;

    & label .form-check-sign::before,
    & label .form-check-sign::after {
      top: -17px;
      left: 4px;
    }
  }

  .btn {
    margin: 0;
  }

  small,
  .small {
    font-weight: 300;
  }

  .card-tasks .card-body & {
    margin-bottom: 0;

    > thead > tr > th,
    > tbody > tr > th,
    > tfoot > tr > th,
    > thead > tr > td,
    > tbody > tr > td,
    > tfoot > tr > td {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  > thead > tr > th {
    font-size: 14px;
    font-weight: $font-weight-bold;
    padding-bottom: 0;
    text-transform: uppercase;
    border: 0;
  }

  .radio,
  .checkbox {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    width: 15px;

    .icons {
      position: relative;
    }

    label {
      &:after,
      &:before {
        top: -17px;
        left: -3px;
      }
    }
  }
  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    padding: 12px 7px;
    vertical-align: middle;
    white-space: nowrap;
  }

  .th-description {
    max-width: 150px;
  }
  .td-price {
    font-size: 26px;
    font-weight: $font-weight-light;
    margin-top: 5px;
    position: relative;
    top: 4px;
    text-align: right;
  }
  .td-total {
    font-weight: $font-weight-bold;
    font-size: $font-size-h5;
    padding-top: 20px;
    text-align: right;
  }

  .td-actions .btn {
    margin: 0px;
  }

  > tbody > tr {
    position: relative;
  }
}

.table-shopping {
  > thead > tr > th {
    font-size: $font-size-h6;
    text-transform: uppercase;
  }
  > tbody > tr > td {
    font-size: $font-paragraph;

    b {
      display: block;
      margin-bottom: 5px;
    }
  }
  .td-name {
    font-weight: $font-weight-normal;
    font-size: 1.5em;
    small {
      color: $dark-gray;
      font-size: 0.75em;
      font-weight: $font-weight-light;
    }
  }
  .td-number {
    font-weight: $font-weight-light;
    font-size: $font-size-h4;
  }
  .td-name {
    min-width: 200px;
  }
  .td-number {
    text-align: right;
    min-width: 170px;

    small {
      margin-right: 3px;
    }
  }

  .img-container {
    width: 120px;
    max-height: 160px;
    overflow: hidden;
    display: block;

    img {
      width: 100%;
    }
  }
}

.table-scroll {
  overflow: scroll;
  display: grid;
}

.table-responsive {
  overflow: scroll;
  padding-bottom: 10px;
}

#tables .table-responsive {
  margin-bottom: 30px;
}

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5;
}

.regular-table-body {
  &:last-child {
    text-align: right;
  }
}

.ToolbarContainer {
  display: flex;
  gap: 16px;
  cursor: pointer;
  padding-bottom: 8px;
  flex-wrap: wrap;
}

.table-action-link {
  display: flex;
  gap: 4px;
  cursor: pointer;
  @extend .link-text;

  span {
    @extend .oath-utility;
    text-transform: capitalize;
  }
}

.table-action-row {
  display: flex;
  margin: 0 0 4px;
}

//Drag N Drop table

.draggable-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 6px;
}

.draggable-table th {
  @extend .oath-bodysmall;
  font-weight: 100;
  text-align: left;
  label {
    max-width: 300px;
    margin-bottom: 0;
  }
  &:nth-last-child(-n+2) {
    &.quantity-th {
      padding-left: 40px;
    }
  }
}

.draggable-table td {
  padding: 4px;
  text-align: left;
  @extend .oath-body;
  color: $inkBlue;
  border-top: 1px solid $lightGray;
  border-bottom: 1px solid $lightGray;

  &:first-child {
    border-left: 1px solid $lightGray;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &.icon-td {
    text-align: right;
    border-right: 1px solid $lightGray;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-right: 15px;
  }
  &:last-child {
    border: none;
    text-align: right;
  }
  &:nth-last-child(2) {
    &.quantity-th {
      border: none;
      text-align: left;
      padding: 0 0 0 40px;
  
      td {
        padding: 0;
      }
    }
  }
}

.draggable-table tr.dragging {
  background-color: $white;
  opacity: 0.7;

  td {
    border-color: $oathBlue;
  }
}

.draggable-table tr:hover {
  cursor: move;
}

.drag-n-drop-below-table {
  width: 80%;
}

.drag-n-drop-below-table th {
  @extend .oath-bodysmall;
  font-weight: 100;
  text-align: left;
  label {
    width: 300px;
    margin-bottom: 0;
  }
}

.drag-n-drop-below-table td {
  padding: 4px;
  text-align: left;
  @extend .oath-body;
  color: $inkBlue;

  input {
    background-color: $white !important;
  }
}

.delete-tickets-column {
  display: grid;
  padding: 0;
  justify-content: flex-end;
  align-content: space-evenly;
}

.upload-container-with-preview {
  display: flex;
  align-items: center;
  position: relative;
}

.status-tab {
  color: $white;
  margin: 0 16px;
  cursor: pointer;
  
  &.in-progress {
    background-color: $oathBlue;
    border-radius: 4px;
    padding: 6px 12px;
  }

  &.error {
    background-color: $errorRed;
    border-radius: 4px;
    padding: 6px 12px;
  }

  &.success {
    background-color: $success;
    border-radius: 16px;
    padding: 2px 6px;
  }
}

.form-check-inline .form-check-input {
  margin-right: 0;
}

.create-page-title {
  @extend .oath-eyebrow;
  color: $inkBlue;
  padding-bottom: 2px;
}